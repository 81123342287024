import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { unregister } from "./registerServiceWorker";
import appConstants from "./helpers/appConstants";
import "./assets/css/bootstrap.css";

const baseUrl =
  document.getElementsByTagName("base")[0].getAttribute("href") || undefined;
const rootElement = document.querySelector("body");
export const adminUrl = baseUrl + appConstants.ADMIN_APP_BASE_PATH;
if (window.location.pathname.indexOf(adminUrl) === 0) {
  import("./AdminApp/App").then(({ default: AdminApp }) => {
    render(<AdminApp />, rootElement);
  });
} else {
  import("./App").then(({ default: App }) => {
    if (rootElement.hasChildNodes()) {
      hydrate(
        <BrowserRouter basename={baseUrl}>
          <App />
        </BrowserRouter>,
        rootElement
      );
    } else {
      render(
        <BrowserRouter basename={baseUrl}>
          <App />
        </BrowserRouter>,
        rootElement
      );
    }
  });
}

unregister();
