const path = "patron";
const appConstants = {
    ADMIN_APP_BASE_PATH: path,
    ADMIN_APP_BASE_URL: `/${path}`,
    withAdminBaseUrl: url => {
        return `${appConstants.ADMIN_APP_BASE_URL}${url}`;
    },
    DEAFULT_COLORS: [
        "Beige",
        "Black",
        "Blue",
        "Brown",
        "Burgundy",
        "Green",
        "Grey",
        "Multicolour",
        "Orange",
        "Pink",
        "Purple",
        "Red",
        "Silver",
        "White",
        "Yellow"
    ],
    DEAFULT_OPENING_HR_V2: [
        "By Appointment Only",
        "Closed",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30"
    ],
    DEAFULT_OPENING_HR: [
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        1,
        2,
        3,
        4,
        5,
        6
    ],
    DEAFULT_CLOSING_HR: [
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11
    ],
    DEAFULT_CLOSING_HR_V2: [
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30"
    ],
    DEAFULT_OPENING_CLOSING_MIN: [
        0,
        15,
        30,
        45
    ],
    DEFAULT_BODY_TYPES: [
        "Adventure",

        "Classic",

        "Cruiser",

        "Dirt Bike",

        "Drag Bike​",

        "E-Bike",

        "Enduro",

        "Minibike",

        "Moped",

        "Motocross",

        "Naked",

        "Quad/ATV",

        "Race",

        "Retro",

        "Scooter",

        "Sidecar",

        "Sport Tourer​",

        "Sports",

        "Supermoto",

        "Supersport",

        "Tourer",

        "Trail",

        "Trials",

        "Trike"
    ],
    ADVERT_STORAGE_ID: "__TEMP_ADVERT_ID",
    ADVERT_TITLE_LIMIT: 50,
};

export default appConstants;
